import React from 'react'
import './experience.css'
import { BsPatchCheckFill } from 'react-icons/bs'
import data from '../../assets/data.json'

const Experience = () => {
  return (
    <section id='experience'>
      <h5>What Skills I Have</h5>
      <h2>My Experience</h2>

      <div className="container experience__container">
        <div className="experience__backend">
          <h3>Backend Development</h3>
          <div className="experience__content">
            {
              data.backend_development.map((backend, index) => {
                return (
                  <article key={index} className="experience__details">
                    <BsPatchCheckFill className='experience__details-icon' />
                    <div>
                      <h4>{backend.technology}</h4>
                      <small className='text-light'>{backend.expertise}</small>
                    </div>
                  </article>
                )
              })
            }
          </div>
        </div>
        <div className="experience__frontend">
          <h3>Frontend Development</h3>
          <div className="experience__content">
            {
              data.frontend_development.map((frontend, index) => {
                return (
                  <article key={index} className="experience__details">
                    <BsPatchCheckFill className='experience__details-icon' />
                    <div>
                      <h4>{frontend.technology}</h4>
                      <small className='text-light'>{frontend.expertise}</small>
                    </div>
                  </article>
                )
              })
            }
          </div>
        </div>
      </div>
    </section>
  )
}

export default Experience
