import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'
import { FaFacebookF } from 'react-icons/fa'


const HeaderSocials = () => {
  return (
    <div className="header__socials">
      <a href="https://www.linkedin.com/in/vivek-kumar-80a41b105" target="_blank"><BsLinkedin /></a>
      <a href="https://github.com/vivek25894" target="_blank"><FaGithub /></a>
      <a href="https://www.facebook.com/vivek250894/"><FaFacebookF /></a>
    </div>
  )
}

export default HeaderSocials
