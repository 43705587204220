import React from 'react'
import './footer.css'
import { FaFacebookF } from 'react-icons/fa'
import {FaGithub} from 'react-icons/fa'
import {BsLinkedin} from 'react-icons/bs'

const Footer = () => {
  return (
    <footer>
      <a href="#" className="foorter__logo">VIVEK</a>

      <ul className="permalinks">
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#education">Education</a></li>
        <li><a href="#testimonial">Testimonials</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://www.linkedin.com/in/vivek-kumar-80a41b105" target="_blank"><BsLinkedin /></a>
        <a href="https://github.com/vivek25894" target="_blank"><FaGithub /></a>
        <a href="https://www.facebook.com/vivek250894/" target="_blank"><FaFacebookF /></a>
      </div>

      <div className="footer__copyright">
        <small>&copy; VIVEK KUMAR. All rights reserved.</small>
      </div>
    </footer>
  )
}

export default Footer
