import React from 'react'
import './testimonial.css'
import data from '../../assets/data.json'

import { Pagination } from 'swiper'

import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'

const Testimonial = () => {
  return (
    <section id='testimonial'>
      <h5>Review From Clients</h5>
      <h2>Testimonials</h2>

      <Swiper className="container testimonials__container"
        // install Swiper modules
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}>
        {
          data.testimonials.map((client, index) => {
            return (
              <SwiperSlide key={index}>
                <article className="testimonials">
                  <div className="client__avatar">
                    <img src={require(`../../assets/${client.image}.jpg`)} alt={client.name} />
                  </div>
                  <h5 className="client__name">{client.name}</h5>
                  <small className="client__review">{client.review}</small>
                </article>
              </SwiperSlide>
            )
          })
        }
      </Swiper>
    </section>
  )
}

export default Testimonial
