import React from 'react'
import './education.css'
import {CgComponents} from 'react-icons/cg'
import data from '../../assets/data.json'

const Education = () => {
  return (
    <section id='education'>
      <h5>My Education Details</h5>
      <h2>Education</h2>
      <div className="container education__container">
        {
          data.education.map((education,index)=>{
            return (
              <article key={index} className="education">
                <div className="education__head">
                  <h3>{education.name}</h3>
                </div>
                <ul className='education__list'>
                  {
                    education.details.map((detail,index)=>{
                      return (
                        <li key={index}>
                          <CgComponents className='education__list-icon' />
                          <p>{detail}</p>
                        </li>
                      )
                    })
                  }
                </ul>
              </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Education
