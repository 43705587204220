import React from 'react'
import './about.css'
import ME from '../../assets/me-about.jpg'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'
import data from '../../assets/data.json'

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About" className="" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className='about__icon' />
              <h5>Experience</h5>
              <small>{data.experience}</small>
            </article>
            <article className="about__card">
              <FiUsers className='about__icon' />
              <h5>Working with</h5>
              <small>{data.working_with}</small>
              <div className="about__Previous">Previous<br />
                <div className="about__Previous-Modal">
                  {
                    data.previous.map((company,index)=>{
                      return(
                        <div key={index}>{company}</div>
                      )
                    })
                  }
                </div>
              </div>
            </article>
            <article className="about__card">
              <VscFolderLibrary className='about__icon' />
              <h5>Projects</h5>
              <small>{data.projects}</small>
            </article>
          </div>
          <p>{data.about_me}</p>
          <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About
