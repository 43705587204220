import React, { useState } from 'react'
import './portfolio.css'
import { CgChevronDoubleRight } from 'react-icons/cg'
import { RiCloseFill } from 'react-icons/ri'
import { BsArrowRightShort } from 'react-icons/bs'
import data from '../../assets/data.json'

const Portfolio = () => {
  //Project Details Business Logic
  let projData = data.portfolio;
  const [projects, setProjects] = useState(projData.filter(proj => proj.category === "Web"));

  //Category Data Business Logic
  const catData = data.portfolio_categories;
  const [categories, setCategories] = useState(catData);

  const handleActiveCategory = name => {
    setCategories(prevCats =>
      [...prevCats].map(cat =>
        cat.name === name ? ({ ...cat, active: true }) : ({ ...cat, active: false }))
    )
    if (name === "All") {
      projData = data.portfolio;
      setProjects(projData)
    } else {
      projData = data.portfolio;
      setProjects(projData.filter(proj => proj.category === name))
    }
  }

  let project = { "id": 0, "category": "", "title": "", "overview": "", "roles_responsibility": [], "environment": "" };
  const [seeMore, setSeeMore] = useState(project);
  const [showModal, setShowModal] = useState(false);

  const handleSeeMoreClick = (id) => {
    project = data.portfolio.filter(proj => proj.id === id);
    setSeeMore(project[0]);
    console.log(project[0]);
    console.log(seeMore);
    setShowModal(true);
  }

  const handleSeeMoreClose = () => {
    project = { "id": 0, "category": "", "title": "", "overview": "", "roles_responsibility": [], "environment": "" };
    setSeeMore(project);
    setShowModal(false);
  }

  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="work__filters">
        {
          categories.map((category, index) => {
            return (
              <span key={index} onClick={() => handleActiveCategory(category.name)} className={`work__item ${(category.active) ? 'active-work' : ''}`}>{category.name}</span>
            )
          })
        }
      </div>
      <div className="container portfolio__container">
        {
          projects.map((proj) => {
            return (
              <article key={proj.id} className="portfolio__item">
                <h3>{proj.title}</h3>
                <div className="portfolio__button" onClick={() => handleSeeMoreClick(proj.id)} >
                  See more <CgChevronDoubleRight className="portfolio__button-icon" />
                </div>
              </article>
            )
          })
        }
      </div>
      <div className={`portfolio__modal ${(showModal) ? 'active-modal' : ''}`}>
        <div className="portfolio__modal-content">
          <RiCloseFill className="portfolio__modal-close" onClick={() => handleSeeMoreClose()} />
          <h3>{seeMore.title}</h3>
          <p className="portfolio__modal-overview">
            <small>Project Overview: </small>{seeMore.overview}
          </p>
          <div style={{ "marginBottom": "0.75rem" }}>
            <small>Roles and Responsibilities:</small>
          </div>
          <ul className="portfolio__modal-list">
            {
              seeMore.roles_responsibility.map((role, index) => {
                return (
                  <li key={index} className="portfolio__modal-item">
                    <div style={{"display":"flex"}}>
                    <div><BsArrowRightShort className="portfolio__modal-icon" /></div>
                    <div><p className="portfolio__modal-info">{role}</p></div>
                    </div>
                  </li>
                )
              })
            }
          </ul>
          <p className="portfolio__modal-overview" style={{"marginTop":"0.75rem"}}>
            <small>Environment: </small>{seeMore.environment}
          </p>
        </div>
      </div>
    </section>
  )
}

export default Portfolio
